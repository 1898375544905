import React from "react";
import { useState, useEffect } from "react";

import {
  Table,
  DatePicker,
  Select,
  Button,
  Popconfirm,
  message,
  Skeleton,
  Input,
  Spin,
} from "antd";

import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";

import { fetchOrders, editOrderByID } from "../utils/fetchOrders";
import { fetchProducts, fetchAllProductsPrices } from "../utils/fetchProducts";
import { fromDatetoYearMonthDay } from "../utils/utils";

const { format } = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const { REACT_APP_DEBUG_MODE: DEBUG_MODE } = process.env;
const FULL_DEBUG_MODE = true;

const CheckPrices = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsPrices, setProductsPrices] = useState([]);
  const [loading, setLoading] = useState({
    Orders: false,
    Products: false,
    ProductsPrices: false,
    "Verifying Prices": false,
  });
  const [loadingMessage, setLoadingMessage] = useState("");
  const [orderLoadingState, setOrderLoadingState] = useState({});

  useEffect(() => {
    let message = "";
    if (loading.Orders) {
      message = `Cargando los Pedidos para ${selectedDate}...`;
    } else if (loading.Products) {
      message = "Cargando los Productos...";
    } else if (loading.ProductsPrices) {
      message = "Cargando los precios de los productos...";
    } else if (loading["Verifying Prices"]) {
      message = "Verificando los precios de los productos...";
    }
    setLoadingMessage(message);
  }, [loading]);

  useEffect(() => {
    const fetchDataAndComparePrices = async (deliveryDate) => {
      try {
        // Getting the orders
        changeLoading("Orders", true, loading, setLoading);
        const orders = await fetchOrders(null, deliveryDate);
        setOrders(orders);
        changeLoading("Orders", false, loading, setLoading);

        // Getting the products
        changeLoading("Products", true, loading, setLoading);
        const products = await fetchProducts();
        setProducts(products);
        changeLoading("Products", false, loading, setLoading);

        // Getting products' prices
        changeLoading("ProductsPrices", true, loading, setLoading);
        const productsPrices = await fetchAllProductsPrices(products);
        setProductsPrices(productsPrices);
        changeLoading("ProductsPrices", false, loading, setLoading);

        // Verifying Prices
        changeLoading("Verifying Prices", true, loading, setLoading);
        const newFilteredOrders = comparePrices(orders, productsPrices);
        setFilteredOrders(newFilteredOrders);
        changeLoading("Verifying Prices", false, loading, setLoading);

        // Checks
        DEBUG_MODE && console.log("[CHECK PRICES] Orders", orders);
        DEBUG_MODE &&
          console.log("[CHECK PRICES] Products Prices", productsPrices);
        DEBUG_MODE &&
          console.log("[CHECK PRICES] Filtered Orders", newFilteredOrders);
      } catch (error) {
        DEBUG_MODE && console.log(error);
        setOrders([]);
        setProducts([]);
        const newLoadingObject = {
          Orders: false,
          Products: false,
          ProductsPrices: false,
          "Verifying Prices": false,
        };
        setLoading(newLoadingObject);
      }
    };
    if (selectedDate == null) {
      // Set everything to null
      setSelectedDate(null);
      setFilteredOrders([]);
      setOrders([]);
      setProducts([]);
      setProductsPrices([]);
      setOrderLoadingState({});
    } else {
      fetchDataAndComparePrices(selectedDate);
    }
  }, [selectedDate]);

  const changePrice = async (order) => {
    try {
      setOrderLoadingState((prevState) => ({
        ...prevState,
        [order.daily_id]: { loading: true, success: false },
      }));

      const newData = { ...order, amount: order.calculatedTotalAmount };
      const result = await editOrderByID(newData);

      setOrderLoadingState((prevState) => ({
        ...prevState,
        [order.daily_id]: { loading: false, success: true },
      }));

      return result;
    } catch (error) {
      message.error("Error al actualizar la información.");
      setOrderLoadingState((prevState) => ({
        ...prevState,
        [order.daily_id]: { loading: false, success: false },
      }));
    }
  };

  const changeAllPrices = async () => {
    for (const order of filteredOrders) {
      await changePrice(order);
    }
    message.success("Acción realizada correctamente.");
  };

  const columns = [
    {
      title: "N°",
      dataIndex: "daily_id",
      key: "daily_id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Comuna",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Productos",
      dataIndex: "verifiedProducts",
      key: "verifiedProducts",
      render: (products) => (
        <ul>
          {products.map((product) => (
            <li key={product.id}>
              {product.name} - {product.quantity} x {product.price}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => format(text),
    },
    {
      title: "Nuevo Monto",
      dataIndex: "calculatedTotalAmount",
      key: "calculatedTotalAmount",
      render: (text, record) => format(text),
    },
    {
      title: "Acción",
      key: "action",
      render: (text, record) => {
        const { loading, success } = orderLoadingState[record.daily_id] || {};
        return loading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : success ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <Button onClick={() => changePrice(record)}>Change Price</Button>
        );
      },
    },
  ];

  return (
    <div className="h-screen">
      {/* First Section */}
      <div className="fixed top-20 z-10 bg-white w-[80%]">
        <div className="font-bold text-3xl my-5">Verifcar Precios</div>
        {/* Section to select */}
        <div className="mx-2">
          <div className="">
            Selecciona una fecha de entrega para verifcar los precios.
          </div>
          <div>
            <DatePicker
              className="mt-5"
              onChange={(value) => {
                const dateString =
                  value === null ? null : fromDatetoYearMonthDay(value.$d);
                setSelectedDate(dateString);
              }}
            />
          </div>
          {/* Loading Indicator */}
          {Object.values(loading).some((val) => val) && (
            <div className="mt-5">
              <Spin indicator={<LoadingOutlined spin />} />
              <span className="mx-3">{loadingMessage}</span>
            </div>
          )}
        </div>
      </div>
      {/* Display Information */}
      <div className="overflow-y-auto mt-36">
        {Object.values(loading).every((val) => !val) &&
          selectedDate !== null && (
            <div className="mt-5">
              {orders.length === 0 ? (
                <span>No hay pedidos agendados para este día.</span>
              ) : filteredOrders.length === 0 ? (
                <span>
                  Todos los pedidos agendados tienen el precio de acuerdo a la
                  base de datos.
                </span>
              ) : (
                <div>
                  <div className="flex justify-end w-full">
                    <Button
                      onClick={changeAllPrices}
                      type="primary"
                      className="mb-3 "
                    >
                      Change All Prices
                    </Button>
                  </div>
                  <Table
                    dataSource={filteredOrders}
                    columns={columns}
                    pagination={false}
                    // scroll={{ y: "75vh" }}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default CheckPrices;

const changeLoading = (key, value, loadingObject, setLoadingObject) => {
  const newLoadingObject = { ...loadingObject, [key]: value };
  setLoadingObject(newLoadingObject);
};

const comparePrices = (orders, products) => {
  // Filter all orders that doesnt match with the current prices.
  const ordersNewPrice = orders.map((order) => {
    const verifiedProducts = order.products.map((orderProduct) => {
      // For each product inside an order

      FULL_DEBUG_MODE &&
        DEBUG_MODE &&
        console.log(
          "[CHECK PRICES / Compare Prices] Order Product Quantity",
          orderProduct
        );

      // 1. Get the product
      const product = products.find(
        (product) => product.product_id === orderProduct.product_id
      );
      FULL_DEBUG_MODE &&
        DEBUG_MODE &&
        console.log("[CHECK PRICES / Compare Prices] Found Product", product);

      // 2. Calculate Price
      const price = product.has_offer
        ? product.prices.find(
            (price) => price.quantity === orderProduct.quantity
          ).price
        : product.prices[0].price * orderProduct.quantity;
      FULL_DEBUG_MODE &&
        DEBUG_MODE &&
        console.log("[CHECK PRICES / Compare Prices] Found Price", price);
      return {
        id: product.product_id,
        name: product.name,
        quantity: orderProduct.quantity,
        price: price, // Calculated Price
      };
    });

    const calculatedTotalAmount = verifiedProducts.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);
    return {
      ...order,
      calculatedTotalAmount,
      verifiedProducts,
    };
  });
  const filteredOrders = ordersNewPrice.filter((order) => {
    return order && order.amount !== order.calculatedTotalAmount;
  });
  return filteredOrders;
};

// Should receives the order with the new information
