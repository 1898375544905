import React, { useState, useEffect, useRef, Suspense } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "../index.css";
import Alert from "@mui/material/Alert";

import {
  convertToChileTime,
  fromDatetoYearMonthDay,
  titleCase,
} from "../utils/utils";
import { fetchOrders, removeOrders } from "../utils/fetchOrders";

import DataTable from "../components/DataTable";
import { ThreeDot } from "react-loading-indicators";

import { generateExcelFromJSONData } from "../utils/generateExcel";

import {
  Header,
  DataFilters,
  CustomDatePicker,
  Test,
  // Button,
} from "../components";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  Table,
  DatePicker,
  Select,
  Button,
  Popconfirm,
  message,
  Space,
  Skeleton,
  Layout,
  Breadcrumb,
  Input,
} from "antd";
import { DownOutlined, DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import {
  HomeOutlined,
  UserOutlined,
  ProductOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";

const { format } = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const { Content } = Layout;
const { Search } = Input;

const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search.replace("?", ""));
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const [searchText, setSearchText] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [paramSearch, setParamSearch] = useState(
    Array.from(queryParams.keys())[0]
      ? Array.from(queryParams.keys())[0]
      : "createdAt"
  );

  // Variable to show loading animation
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true); // change it to false when data fetched
  const [dataError, setDataError] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);

  const setSearchDateWithParam = (date, searchParam) => {
    const newDate = date ? date : fromDatetoYearMonthDay(new Date());
    console.log("ESTA ES LA NUEVA FECHA PARA SEARCH DATE: ", newDate);
    setSearchDate(newDate);
    queryParams.set(searchParam, newDate);
    navigate(`/orders?${queryParams.toString()}`, { replace: true }); // Go to the link in case date not exists
  };

  useEffect(() => {
    const createdAtQueryParams = queryParams.get("createdAt");
    const deliveryDateQueryParams = queryParams.get("deliveryDate");
    // console.log(
    //   "Getting created date from query params: " + createdAtQueryParams
    // );
    // console.log(
    //   "Getting delivery date from query params: " + deliveryDateQueryParams
    // );

    let createdAt = null;
    let deliveryDate = null;

    if (createdAtQueryParams === null && deliveryDateQueryParams === null) {
      setSearchDateWithParam(null, "createdAt");
      createdAt = fromDatetoYearMonthDay(new Date());
      setParamSearch("createdAt");
    } else {
      if (createdAtQueryParams !== null) {
        // if there exists createdAt
        setSearchDateWithParam(createdAtQueryParams, "createdAt");
        createdAt = createdAtQueryParams;
        setParamSearch("createdAt");
      } else if (deliveryDateQueryParams !== null) {
        // if there exists deliveryDate
        setSearchDateWithParam(deliveryDateQueryParams, "deliveryDate");
        deliveryDate = deliveryDateQueryParams;
        setParamSearch("deliveryDate");
      }
    }

    // Handling fetchingData
    setIsDataLoading(true);
    setDataError(null);
    const fetchData = async (createdAt, deliveryDate) => {
      try {
        const vals = await fetchOrders(createdAt, deliveryDate);
        setData(vals);
        setOriginalData(vals);
        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setOriginalData(null);
        setDataError(error);
        setIsDataLoading(false);
      }
    };
    fetchData(createdAt, deliveryDate);
  }, [refreshPage]);

  const columns = [
    {
      title: "Creado a las",
      dataIndex: "created_at",
      width: 120,
      render: (cell) => {
        return new Date(cell).toLocaleDateString("es-ES", {
          // weekday: "long",
          // year: "numeric",
          // month: "long",
          // day: "numeric",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    ,
    {
      title: "ID",
      dataIndex: "daily_id",
      width: 75,
      showSorterTooltip: {
        target: "full-header",
      },
      sorter: (a, b) => Number(a.daily_id) - Number(b.daily_id),
      // sortDirections: ["descend"],
      // render: (text, data, index) => (
      //   <>
      //     <Popover
      //       placement="right"
      //       title={text}
      //       content={`Here's pop up Text ${index} and ${data.address}`}
      //       trigger="hover"
      //     >
      //       {/* <div className="">{text}</div> */}
      //       <div className="absolute top-0 w-full min-h-14"></div>
      //       <div>{text}</div>
      //     </Popover>
      //   </>
      // ),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: 120,
    },
    {
      title: "Dirección",
      dataIndex: "address",
      width: 200,
      render: (text, row, index) => (
        <div>
          <span className="font-bold">{titleCase(row.city)}</span>, {text}
        </div>
      ),
    },
    {
      title: "Vendedor",
      dataIndex: "seller_name",
      width: 100,
    },
    {
      title: "Productos",
      dataIndex: "products",
      width: 250,
      render: (products) => (
        <div className="flex-column space-y-1">
          {products?.map((product, index) => (
            <>
              <div className="px-2">
                {product.quantity} x {product.product_name} - {product.variant}
              </div>
            </>
          ))}
        </div>
      ),
    },
    {
      title: "Monto",
      dataIndex: "amount",
      width: 100,
      render: (amount) => format(amount),
    },
    // {
    //   title: "Observación",
    //   dataIndex: "delivery_info_array",
    //   width: 150,
    //   render: (delivery_info_array) => (
    //     <div>
    //       {delivery_info_array[delivery_info_array.length - 1].delivery_note}
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "order_id",
      width: 100,
      render: (order_id) => <Link to={`/orders/${order_id}`}>Detalle...</Link>,
    },
  ];

  const [showMessage, setShowMessage] = useState(false);

  const updateUrlQueryParams = () => {
    navigate(`/orders?${queryParams.toString()}`, {
      replace: true,
    });
  };

  const actionComplete = (args) => {
    if (args.requestType === "save") {
      console.log(args.data);
      console.log("EMPUJAR DATA DE AQUI PARA LA BASE DE DATOS");
      setShowMessage(true);
      console.log(showMessage);
    }
  };

  // Donwload Data in Excel
  const handleDownloadClick = async () => {
    console.log(searchDate);
    generateExcelFromJSONData(data, searchDate);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const handleRemoveOrders = async () => {
    const removeOrdersID = data
      .filter((item) => selectedRows.includes(item.key))
      .map((item) => item.order_id);
    try {
      removeOrders(removeOrdersID).then((value) => {
        setRefreshPage(!refreshPage);
      });
    } catch (error) {
      throw Error(error.message);
    }
    message.success("Ordenes eliminadas correctamente.");
  };

  const handleParamSearch = (paramDate) => {
    queryParams.forEach((value, key) => {
      queryParams.delete(key);
    });
    queryParams.set(paramDate, searchDate);
    console.log("Estoy cambiando la cosa", paramDate);
    console.log("searchDate", searchDate);
    updateUrlQueryParams();
    setRefreshPage(!refreshPage);
  };

  const handleUpdateSearchText = (searchText) => {
    const newData = [...originalData];
    console.log("esto es new data", newData);
    setData(
      newData.filter((item) =>
        (
          item.daily_id +
          item.name?.toLowerCase() +
          item.city?.toLowerCase()
        ).includes(searchText?.toLowerCase())
      )
    );
  };

  const searchParamsOptions = [
    {
      value: "createdAt",
      label: "Agendado en",
    },
    {
      value: "deliveryDate",
      label: "Fecha de Entrega",
    },
  ];

  const skeletonColumns = columns.map((column) => ({
    ...column,
    render: (col) => {
      return <Skeleton active title={false} paragraph={{ rows: 1 }} />;
    },
  }));

  return (
    <>
      <div className="">
        {/* Showing data saved correctly */}
        {/* <div className="mb-5 flex justify-between">
          <Header category="Página" title="Pedidos" />
        </div> */}
        {/* <Breadcrumb
          items={[
            {
              href: "/home",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <ShoppingCartOutlined />
                  <span>Pedidos</span>
                </>
              ),
            },
          ]}
          className="mb-5"
        /> */}
        <div className="bg-white rounded-lg">
          <div
            style={{ marginBottom: 16 }}
            className="xl:flex xl:justify-between xl:flex-auto lg:space-y-0  md:justify-between flex flex-wrap justify-center space-y-2"
          >
            <div className="lg:w-auto flex space-x-2 w-full justify-between">
              <Button
                icon={<SyncOutlined />}
                onClick={() => setRefreshPage(!refreshPage)}
              ></Button>
              <div className="space-x-2">
                <Select
                  variant="outlined"
                  className="min-w-40"
                  defaultValue={
                    searchParamsOptions.filter(
                      (item) => item.value === paramSearch
                    )[0]?.label
                  }
                  options={searchParamsOptions}
                  onChange={(value) => {
                    handleParamSearch(value);
                  }}
                ></Select>
                <DatePicker
                  value={dayjs(searchDate)}
                  allowClear={false}
                  onChange={(value) => {
                    process.env.DEBUG_MODE &&
                      console.log("this is the new value of date", value.$d);
                    setSearchDate(fromDatetoYearMonthDay(value.$d));
                    queryParams.set(
                      paramSearch,
                      fromDatetoYearMonthDay(value.$d)
                    );
                    updateUrlQueryParams();
                    setRefreshPage(!refreshPage);
                  }}
                  showToday={false}
                />
              </div>
            </div>
            <Search
              className="mr-2"
              placeholder="N°, Nombre o Comuna ..."
              onSearch={handleUpdateSearchText}
              // enterButton
              style={{ width: 250 }}
              allowClear
              // value={searchText}
              onChange={(event) => {
                // handleUpdateSearchText(event.target.value);
                // console.log("val", event.target.value);
                // setSearchText(event.target.value);
              }}
            />
            <div className="flex space-x-2 xl:justify-normal md:w-auto justify-between w-full">
              <Button
                className="mr-2"
                onClick={() => {
                  window.location.href = baseUrl + "/orders/create";
                }}
                type="dashed"
              >
                Añadir
              </Button>
              <Button
                onClick={handleDownloadClick}
                type="secondary"
                style={{ backgroundColor: "#217346", color: "#fff" }}
              >
                Descargar Excel
              </Button>
              <Popconfirm
                title="¿Estas seguro que deseas eliminar estas órdenes?"
                placement="topRight"
                onConfirm={() => handleRemoveOrders()}
                okText="Eliminar"
                cancelText="Cancelar"
                Disabled
                okButtonProps={{
                  className: "custom-confirm-delete-button",
                  style: {
                    backgroundColor: "#f5222d",
                    borderColor: "#f5222d",
                    color: "#fff",
                  },
                }}
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  disabled={selectedRows.length === 0}
                >
                  Eliminar
                </Button>
              </Popconfirm>
            </div>
          </div>
          {isDataLoading ? (
            <div className="w-full text-center min-h-72 flex justify-center">
              <div className="w-full">
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={skeletonColumns}
                  dataSource={Array.from({ length: 3 }).map((_, index) => ({
                    key: index,
                  }))}
                  pagination={false}
                />
              </div>
            </div>
          ) : dataError ? (
            <div>No hay pedidos agendados en esta fecha.</div>
          ) : (
            <>
              <Content className="w-full">
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={data.map((item, id) => {
                    item.key = id;
                    return item;
                  })}
                  pagination={false}
                  sticky={true}
                  scroll={{ y: "72vh" }}
                  // onRow={(record) => ({
                  //   onClick: () => handleRowClick(record),
                  //   onMouseEnter: () => setHoveredRow(record.key),
                  //   onMouseLeave: () => setHoveredRow(null),
                  //   style: { cursor: "pointer" },
                  // })}
                />
              </Content>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
