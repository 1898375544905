import React from "react";

const Reschedule = () => {
  return (
    <div>
      <div className="font-bold text-3xl">Re-agendar Pedidos</div>
      <div>
        Selecciona una fecha de entrega y una fecha a la cual deseas reagendar.
      </div>
    </div>
  );
};

export default Reschedule;
