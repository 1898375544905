import axios from "axios";
import api from "../api/axios";

export const fetchProducts = async () => {
  const response = await api.get("products/all"); // for production
  // const response = await axios.get(
  //   "https://25veqa-ip-190-22-55-139.tunnelmole.net/products/all"
  // ); // fot dev

  console.log("response in products", response);
  console.log("access Token", localStorage.getItem("token"));
  const result = response.data;
  console.log("results", result);
  // if (result.status === "404") {
  //   throw new Error("Error al cargar los productos.");
  // }

  return result;
  // await fetch(
  //   // process.env.REACT_APP_END_POINT
  //   process.env.REACT_APP_END_POINT + "/products/all",
  //   {
  //     method: "GET",
  //     headers: {
  //       // "ngrok-skip-browser-warning": "a",
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //   }
  // );
  // if (!response.ok) {
  //   throw new Error("Error al conectar con el servidor");
  //   return response;
  // }
};

export const getProductsWeights = async () => {
  const response = await api.get("products/all");
  const weightsObject = {};
  response.data.forEach((product) => {
    weightsObject[product.product_id] = product.weight || 0;
  });
  console.log("this is the weights object product", weightsObject);
  return weightsObject;
};

export const fetchProductsById = async (product_id) => {
  const response = await api.get("products/" + product_id);
  // if (!response.ok) {
  //   throw new Error("Error al conectar con el servidor");
  //   return response;
  // }
  const result = await response.data[0];
  console.log("esto es el resutl de query by prod id", result);
  // if (result.status === "404") {
  //   throw new Error("Error al cargar los productos.");
  // }
  return result;
};

export const fetchProductPricesById = async (product_id) => {
  const response = await api.get("/products/prices/" + product_id);
  if (response.status !== 200) {
    throw new Error("Error al conectar con el servidor");
    return response;
  }
  const result = response.data;

  return result;
};

export const fetchAllProductsPrices = async (products) => {
  const fetchProductPrice = async (product) => {
    // THIS IS THE QUERY
    const data = await fetchProductPricesById(Number(product.product_id));
    // console.log("[PRODUCTS] All products prices query result: ", data);
    return { ...product, prices: data };
  };
  const productsWithPrices = await Promise.all(products.map(fetchProductPrice));
  return productsWithPrices;
};

export const getVariantByProductID = async (productID) => {
  const response = await api.get("/products/variants/" + productID);
  if (response.status !== 200) {
    throw new Error("Error al conectar con el servidor");
    return response;
  }
  const result = response.data;
  console.log(result);
  return result.sort((a, b) => a.id - b.id);
};

export const deleteProduct = async (productsID) => {
  const val = await api.post("/products/delete", { product_id: [productsID] });
  console.log(val);
  return val;
};

export const removePricesProducts = async (pricesID) => {
  const val = await api.post("/products/prices/delete", { price_id: pricesID });
  return val;
};

export const addPricesProducts = async (productID, quantity, price) => {
  const val = await api.post("/products/prices/add", {
    product_id: productID,
    price: price,
    size: quantity,
  });
  return val;
};

export const editStock = async (variantID, stockValue) => {
  const stockString = stockValue ? "enable" : "disable";
  const val = await api.post(
    "/products/" + variantID + "/stock/" + stockString
  );
  return val;
};

export const editHasOffer = async (productID, hasOfferValue) => {
  const hasOfferString = hasOfferValue ? "enable" : "disable";
  // console.log(
  //   "string to post: ",
  //   "/products/offer/" + productID + hasOfferString
  // );
  const val = await api.post(
    "/products/offer/" + productID + "/" + hasOfferString
  );
  return val;
};

export const editProductGeneralInformation = async (productData) => {
  const val = await api.post("/products/edit", productData);
  console.log("esto es el nuevo product data", productData);
  return val;
};

export const addVariant = async (productID, newVariantData) => {
  const sendData = { ...newVariantData, product_id: productID }; // Verificar
  console.log("sendData", sendData);
  const val = await api.post("/products/variants/add", sendData);
  return val;
};

export const deleteVariant = async (variant) => {
  const variantID = variant.id;
  const val = await api.post("/products/variants/delete", {
    variant_id: [variantID],
  });
  return val;
};

export const getMappedProductsWeb = async (productID) => {
  const prodID = Number(productID);
  const val = await api.get("/products/web/map/all");
  const response = val.data.filter((item) => item.product_id === prodID);
  return response;
};

export const addProductsWeb = async (formData) => {
  const val = await api.post("/products/web/map/add", formData);
  return val;
};

export const deleteProductsWeb = async (webProductMapping) => {
  const val = await api.post("/products/web/map/delete", webProductMapping);
  return val;
};
